<template>
        <transition name="fade">
                <div :class="`border-l-4 p-4 ${bgColorClass} ${borderColorClass}`">
                        <div class="flex">
                                <div class="flex-shrink-0">
                                        <ExclamationTriangleIcon class="h-5 w-5" :class="iconColorClass" aria-hidden="true" />
                                </div>
                                <div class="ml-3">
                                        <p class="text-sm" :class="textColorClass">
                                                {{ text }}
                                        </p>
                                </div>
                        </div>
                </div>
        </transition>
</template>

<script setup>
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid'
import { computed, defineProps } from 'vue'
const props = defineProps({
    text: String,
    type: {
        type: String,
        default: 'warning',
        validator: (value) => ['warning', 'success'].includes(value),
    },
})

const bgColorClass = computed(() => (props.type === 'warning' ? 'bg-gold-400' : 'bg-green-400'))
const borderColorClass = computed(() => (props.type === 'warning' ? 'border-gold-600' : 'border-green-600'))
const iconColorClass = computed(() => (props.type === 'warning' ? 'text-gold-800' : 'text-green-800'))
const textColorClass = computed(() => (props.type === 'warning' ? 'text-gold-900' : 'text-green-900'))
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .9s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>