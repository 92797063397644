<template>
    <div class="flex justify-center items-center">
        <div class="w-full md:w-2/4">
            <form @submit.prevent="onSubmit">
                <div class="my-2">
                    <label class="block text-secondary-700 text-sm font-bold mb-2" for="name">
                        Name
                    </label>
                    <input required class="shadow appearance-none border  w-full py-2 px-3 text-primary leading-tight focus:outline-none focus:shadow-outline bg-secondary-600 rounded-none disabled:bg-secondary-700" id="name" type="text" placeholder="Name" v-model="name" :disabled="loading" />
                </div>
                <div class="my-2">
                    <label class="block text-secondary-700 text-sm font-bold mb-2" for="phone">
                        Telefonnummer
                    </label>
                    <input required class="shadow appearance-none border  w-full py-2 px-3 text-primary mb-3 leading-tight focus:outline-none focus:shadow-outline bg-secondary-600 rounded-none disabled:bg-secondary-700" id="phone" type="text" placeholder="Telefonnummer" v-model="phone" :disabled="loading" />
                </div>
                <div class="my-2">
                    <label class="block text-secondary-700 text-sm font-bold mb-2" for="email">
                        Email-Adresse
                    </label>
                    <input required class="shadow appearance-none border  w-full py-2 px-3 text-primary mb-3 leading-tight focus:outline-none focus:shadow-outline bg-secondary-600 rounded-none disabled:bg-secondary-700" id="email" type="email" placeholder="Email-Adresse" v-model="email" :disabled="loading" />
                </div>
                <div class="my-2">
                    <label class="block text-secondary-700 text-sm font-bold mb-2" for="message">
                        Ihre Nachricht
                    </label>
                    <textarea required class="shadow appearance-none border  w-full py-2 px-3 text-primary leading-tight focus:outline-none focus:shadow-outline h-48 bg-secondary-600 rounded-none disabled:bg-secondary-700" id="message" placeholder="Ihre Nachricht" v-model="message" :disabled="loading"></textarea>
                </div>
                <div class="my-2">
                    <button class="btn-dark disabled:bg-secondary-900" type="submit" :disabled="loading">
                        Nachricht abschicken
                    </button>
                </div>
            </form>
            <Notice :type="msg_type" :text="msg_log" v-show="msg_show" />
        </div>

    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import axios from 'axios';
import Notice from './Notice.vue';

const name = ref('');
const phone = ref('');
const email = ref('');
const message = ref('');
const loading = ref(false);

const msg_show = ref(false);
const msg_log = ref('Nachricht wurde erfolgreich verschickt');
const msg_type = ref('success');

const submitForm = () => {
    console.log('submitForm');
    send_mail();
};

const onSubmit = () => {
    console.log(`Form submitted with name: ${name.value}`);
    send_mail();
};



const send_mail = async () => {

    loading.value = true;
    let url;
    if (import.meta.env.MODE === 'development') {
        //console.log('Running in development mode');
        url = 'http://localhost:5000/public/concierge-mailer';
    } else {
        //console.log('Not running in development mode');
        url = 'https://twapi1-nr2qe7lb3q-oa.a.run.app/public/concierge-mailer';
    }
    


    const data = {
        from: `${email.value}`,
        name: `${name.value}`,
        subject: '',
        html_body: `Name: ${name.value}<br> Telefon: ${phone.value}<br>  Mail: ${email.value} <br> <br> Nachicht: ${message.value}`,
    };


    axios.post(url, data)
        .then(response => {
            //console.log('Response:', response.data);
            loading.value = false;
            msg_show.value = true;
            msg_log.value = 'Nachricht wurde erfolgreich verschickt';
            msg_type.value = 'success';
        }).catch(error => {
            //console.log('Error:', error);
            loading.value = false;
            msg_show.value = true;
            msg_log.value = 'Fehler: Nachricht konnte nicht verschickt werden';
            msg_type.value = 'warning';
        });
};
</script>
